import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { dev, version } from '$app/environment';
import { PUBLIC_ENVIRONMENT, PUBLIC_SENTRY_DSN } from '$env/static/public';

const urlParams = new URLSearchParams(window.location.search);
const utmSource = urlParams.has('utm_source')
  ? urlParams.get('utm_source')
  : urlParams.has('fbclid')
    ? 'instagram'
    : 'organic';

const referringDomain = (referrer: string) => {
  const split = referrer.split('/');
  if (split.length >= 3) {
    return split[2];
  }
  return '';
};

const referrer = referringDomain(document.referrer) || 'direct';

if (!dev) {
  if (PUBLIC_ENVIRONMENT === 'test' || PUBLIC_ENVIRONMENT === 'prod') {
    Sentry.init({
      environment: PUBLIC_ENVIRONMENT, // test or prod
      release: version,
      replaysSessionSampleRate: PUBLIC_ENVIRONMENT === 'prod' ? 1.0 : 0,
      replaysOnErrorSampleRate: 1.0,
      dsn: PUBLIC_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
      ],
      tracesSampleRate: 0.8,
    });
    Sentry.setTag('svelteKit', 'browser');
    Sentry.setTag('utm_source', utmSource);
    Sentry.setTag('referrer', referrer);
  } else console.warn('PUBLIC_ENVIRONMENT must be prod or test');
}

// This will catch errors in load functions from +page.ts files
export const handleError = (({ error, event }) => {
  Sentry.captureException(error, { contexts: { sveltekit: { event } } });

  if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    'code' in error &&
    typeof error.message === 'string' &&
    typeof error.code === 'string'
  ) {
    return {
      message: error.message,
      code: error.code,
    };
  }

  return {
    message: 'UNKNOWN ERROR',
    code: 'UNKNOWN_ERROR',
  };
}) satisfies HandleClientError;
